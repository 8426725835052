.footer-mobile {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #002940;
  color: #fff;
  padding: 10px;
  justify-content: space-evenly;
  font-size: 24px;
  align-items: center;
}

a .icon-house {
  font-weight: bold;
  color: #fff;
}

@media (min-width: 601px) {
	.footer-mobile {
		display: none;
	}
}