.main-asistencia {
    display: flex;
    height: 400px;
}

.container-asistencia {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: #0099ff;
}

.container-asistencia h2 {
    margin: 32px 0;
}

.container-asistencia .form-asistencia {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-asistencia .label-asistencia {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.label-asistencia input {
    border-radius: 16px;
    border: none;
    text-align: center;
}

.label-asistencia input:focus {
    outline: none;
    border: none;
}
