.class-card {
    background-color: black;
    border-radius: 15px;
    padding: 20px;
    margin: 10px;
    width: 300px;
    color: white;
}

.class-card h2 {
    margin-top: 10px;
    font-size: 24px;
}

.class-card p {
    margin-top: 5px;
    font-size: 18px;
}

.dividirCard {
    width: 100%;
    height: 2px;
    background-color: white;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }