.container-titulo-filtros {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-titulo-filtros .filtros {
    display: flex;
    gap: 8px;
}

.container-titulo-filtros p {
    margin: 0;
}

.table tbody tr td:first-child::before {
    content: counter(rowNumber);
    counter-increment: rowNumber;
    padding-right: 5px;
}

.table {
    counter-reset: rowNumber;
}

.codegoUsuario-contenido input {
    width: 48px;
    text-align: center;
}

.diasDeCongelamiento-contenido input {
    width: 48px;
    text-align: center;
}