.navbar-socio {
	background: #002940;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 24px;
}

.menu-izquierdo {
	display: flex;
	align-items: center;
	gap: 24px;
}

.menu-izquierdo h6 {
	margin-bottom: 0;
}

.menu-central {
	color: white;
	font-size: 20px;
	display: flex;
	gap: 24px;
}

.menu-derecho {
	display: flex;
	gap: 24px;
}

.menu-derecho a {
	color: white;
}

.grafico-evaluacion {
	max-width: 600px;
}

.body-formulario {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 50px;
}

.clasesCreadas {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@media (max-width: 600px) {
	.menu-derecho {
		display: none;
	}

	.menu-izquierdo {
		width: 100%;
		justify-content: space-between;
	}

	.menu-central {
		display: none;
	}
}