table tr {
	border-bottom: 1px solid #000;
}

table th {
	border-bottom: 1px solid #000;
}

table td {
	padding: 8px;
}