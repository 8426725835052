.perfilSocio-container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1;
}

.logo-beatforce-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 40px 0;
}

.logo-beatforce-perfilSocio {
	max-width: 50%;
  height: auto;
}

@media (min-width: 700px) {
	.logo-beatforce-perfilSocio {
		max-width: 30%;
	}
}