.form-inline {
    display: flex;
    gap: 8px;
}

.ti-bell {
    color: #ffffff;
    /* font-size: 24px; */
    cursor: pointer;
}

.admin-notificacione-text {
    background: #002940;
    color: #ffffff;
    width: 600px;
    height: 70vh;
}

.admin-notificacione-icon {
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-signOut {
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}